<template>
  <b-form
    @submit.prevent="save"
  >
    <validation-observer
      ref="elementForm"
      #default="{ invalid }"
    >
      <b-card>
        <b-card-header>
          <div class="text-primary h2">
            <feather-icon
              icon="StarIcon"
              size="25"
              class="mr-0 mr-sm-50"
              variant="primary"
            />
            <span class="d-none d-sm-inline">Información Principal</span>
          </div>
        </b-card-header>
        <b-card-body>

          <b-row>
            <!-- Right Col: Table -->
            <b-col
              cols="2"
              xl="2"
            >
              <b-form-group
                label-for="key"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.key') }}</span><span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.key')"
                  rules="required"
                >
                  <b-form-input
                    id="key"
                    v-model="elementData.key"
                    maxlength="100"
                    disabled
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Right Col: Table -->
            <b-col
              cols="4"
              xl="4"
            >
              <b-form-group
                label-for="name"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.name') }}</span><span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.name')"
                  rules="required"
                >
                  <b-form-input
                    id="name"
                    v-model="elementData.name"
                    maxlength="100"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="6"
              md="6"
            >
              <b-form-group
                label-for="folder_type"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.folder_type') }}</span><span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.folder_type')"
                  rules="required"
                >
                  <v-select
                    v-model="elementData.folder_type_id"
                    :options="folderTypeList"
                    label="value"
                    value="id"
                    :reduce="option => option.id"
                    :placeholder="$t('list.defaultOption')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

        </b-card-body>
      </b-card>

      <b-row class="d-flex justify-content-end">
        <b-button
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          @click="router().go(-1)"
        >
          {{ $t('actions.back') }}
        </b-button>
        <b-button
          variant="primary"
          type="submit"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :disabled="invalid"
        >
          {{ $t('actions.save') }}
        </b-button>
      </b-row>
    </validation-observer>
  </b-form>

</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BButton, BRow, BCol, BFormInput, BFormGroup, BForm,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import router from '@/router'
import vSelect from 'vue-select'
import '@core/scss/vue/libs/vue-select.scss'

export default {
  components: {
    BCard, BButton, BRow, BCol, BFormInput, BCardHeader, BCardBody, BFormGroup, BForm, ValidationProvider, ValidationObserver, vSelect,
  },
  props: {
    elementData: {
      type: Object,
      required: true,
    },
    newElement: {
      type: Boolean,
      required: true,
    },
    resourcesElementName: {
      type: String,
      required: true,
    },
    folderTypeList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      logoUpdated: false,
      logo: null,
    }
  },
  watch: {
    elementLogo(newValue, oldValue) {
      this.logo = newValue
    },
  },
  methods: {
    router() {
      return router
    },
    resolveUserStatusVariant(status) {
      if (status == 0) return 'warning'
      return 'success'
    },
    resolveUserStatusMessage(status) {
      if (status == 0) return this.$t('user.statusValue.inactive')
      return this.$t('user.statusValue.active')
    },
    save() {
      this.$refs.elementForm.validate().then(success => {
        if (success) {
          if ('folderTypeList' in this.elementData) {
            delete this.elementData.folderTypeList
          }
          this.$parent.save(this.elementData)
        }
      })
    },
  },
}
</script>

<style>

</style>
