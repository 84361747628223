<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="elementData === undefined"
    >
      <h4 class="alert-heading">
        {{ $t(RESOURCES_ELEMENT_NAME + '.search.error.fetching_single_data')}}
      </h4>
      <div class="alert-body">
        {{ $t(RESOURCES_ELEMENT_NAME + '.search.error.check')}}
        <b-link
          class="alert-link"
          :to="{ name: 'apps-documental-type-list'}"
        >
        {{ $t(RESOURCES_ELEMENT_NAME + '.search.error.list')}}
        </b-link>
        {{ $t(RESOURCES_ELEMENT_NAME + '.search.error.to_find_other')}}
      </div>
    </b-alert>


    <template v-if="elementData">
      <element-edit-tab
        :new-element="newElement"
        :element-data="elementData"
        :folder-type-list="folderTypeList"
        :resources-element-name="RESOURCES_ELEMENT_NAME"
        class="mt-2 pt-75"
      />
    </template>
    
  </div>
</template>

<script>
import {
   BAlert, BLink
} from 'bootstrap-vue'
import router from '@/router'
import store from '@/store'
import elementStoreModule from '../storeModule'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ElementEditTab from './EditCard.vue'

export default {
  components: {
    BAlert,
    BLink,
    ElementEditTab: ElementEditTab,
  },
  data() {
    return {
      RESOURCES_ELEMENT_NAME: '',
      ELEMENT_APP_STORE_MODULE_NAME: '',
      newElement: true,
      elementData: {},
      folderTypeList: []
    }
  },
  async mounted() {
    this.RESOURCES_ELEMENT_NAME = 'documental_types'
    this.ELEMENT_APP_STORE_MODULE_NAME = 'apps-dc-type-ed-' + this.$t(this.RESOURCES_ELEMENT_NAME + ".module_name")
    // Register module
    if (!store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME)) store.registerModule(this.ELEMENT_APP_STORE_MODULE_NAME, elementStoreModule)
    this.folderTypeList = await this.fetchKeyValue('folder_type')
    
    if (router.currentRoute.params.id !== undefined) {
      await this.fetchElement()
    } else {
      this.elementData = {status: 1}
    }
    
  },
  unmounted() {
    // UnRegister on leave
    if (store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME)) store.unregisterModule(this.ELEMENT_APP_STORE_MODULE_NAME)
  },
  methods: {
    async fetchKeyValue(type) {
      return store.dispatch(this.ELEMENT_APP_STORE_MODULE_NAME + '/fetch_key_value', { type:type })
      .then(response => { 
        var list = response.data.items 
        list.unshift({ id: null, value: this.$t('list.defaultOption') })
        return list
      })
      .catch(error => {
        if (error.response.status === 404) {
          return []
        }
      })
    },
    fetchElement() {
      this.newElement = false
      store.dispatch(this.ELEMENT_APP_STORE_MODULE_NAME + '/fetch_element', { id: router.currentRoute.params.id })
        .then(response => { 
          this.elementData = response.data 
          if (this.elementData.logo_id) {
            this.fetchLogo(this.elementData.logo_id)
          }
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.elementData = undefined
          }
        })

    },
    save(elementData) {
      let method = this.ELEMENT_APP_STORE_MODULE_NAME + (this.newElement ? '/add_element' : '/save_element')
      let action = this.newElement ? 'creation' : 'update'
      var _self = this
      store
        .dispatch(method, {data: elementData})
        .then(response => {
          _self.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: _self.$t(_self.RESOURCES_ELEMENT_NAME + '.action_results.'+action+'.title'),
              icon: 'SaveIcon',
              variant: 'success',
              text: _self.$t(_self.RESOURCES_ELEMENT_NAME + '.action_results.'+action+'.success'),
            },
          })
          console.log(_self.ELEMENT_APP_STORE_MODULE_NAME+'-list')
          _self.$router.replace({ name: 'apps-documental-type-list' })
        })
        .catch((error) => {
          _self.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: _self.$t(_self.RESOURCES_ELEMENT_NAME + '.action_results.'+action+'.title'),
              icon: 'SaveIcon',
              variant: 'danger',
              text: _self.$t(_self.RESOURCES_ELEMENT_NAME + '.action_results.'+action+'.failure'),
            },
          })
        })
    }
  }
}
</script>

<style>

</style>
